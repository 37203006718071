function yunu_form(config, domain = 'http://crm.yunucms.com') {
    layui.use(['layer', 'form'], function(){
        const layer = layui.layer;
        const form = layui.form;

        layer.open({
            type: 1,
            title: '合作申请',
            area: ['558px', '549px'],
            skin: 'yunu_form',
            content: `
                <div class="yunu_form_agent"><span class="pc">填写表单立即获取产品资料及价格体系表</span><span class="wap">填写表单立即获取产品资料</span></div>
                <form class="layui-form">
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司名称<img class="yunu_form_icon" src="${domain}/static/form/img/agent01.png" /></label>
                        <div class="layui-input-block">
                            <input class="layui-input" type="text" name="name" lay-verify="required" placeholder="请输入您的公司名称">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人<img class="yunu_form_icon" src="${domain}/static/form/img/agent02.png" /></label>
                        <div class="layui-input-block">
                            <input class="layui-input" type="text" name="contacts" lay-verify="required" placeholder="请输入您的联系人">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机号<img class="yunu_form_icon" src="${domain}/static/form/img/agent03.png" /></label>
                        <div class="layui-input-block">
                            <input class="layui-input" type="text" name="tel" id="yunu_form_tel" value="${config.tel ?? ''}" lay-verify="required" placeholder="请输入您的手机号">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">验证码<img class="yunu_form_icon" src="${domain}/static/form/img/agent04.png" /></label>
                        <div class="layui-input-block">
                            <input class="layui-input" type="text" name="vcode" lay-verify="required" placeholder="请输入您的验证码">
                            <div class="yunu_form_vcode" id="yunu_form_vcode">获取验证码</div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">微信号<img class="yunu_form_icon" src="${domain}/static/form/img/agent05.png" /></label>
                        <div class="layui-input-block">
                            <input class="layui-input" type="text" name="wx" placeholder="请输入您的微信号">
                        </div>
                    </div>
                    <button class="layui-btn yunu_form_submit" lay-submit="" lay-filter="yunu_form">立即提交合作申请</button>
                </form>
            `,
            success: function () {
                form.on('submit(yunu_form)', function({ field }){
                    layer.load();
                    $.ajax({
                        method: 'POST',
                        url: `${domain}/index.php/api/form.html`,
                        crossDomain: true,
                        xhrFields: {
                            withCredentials: true
                        },
                        data: {
                            ...field,
                            ...config
                        },
                    }).then(res =>{
                        if (res.state == 1) {
                            layer.closeAll();
                            layer.alert(res.info, {
                                btn: '知道了'
                            });
                        } else {
                            layer.closeAll('loading');
                            layer.msg(res.info);
                        }
                    });
                    return false;
                });

                let num = 0;
                let yunu_form_vcode = $('#yunu_form_vcode');
                let vcode_number_handle = function () {
                    num = 60;
                    yunu_form_vcode.html(num+'秒后重试');
                    let timer = setInterval(function () {
                        num--;
                        if (num == 0) {
                            clearInterval(timer);
                            yunu_form_vcode.html('获取验证码')
                        } else {
                            yunu_form_vcode.html(num+'秒后重试')
                        }
                    }, 1000)
                }

                yunu_form_vcode.on('click', function () {
                    let tel = $('#yunu_form_tel').val();
                    if (!tel) {
                        layer.msg('请输入您的手机号');
                        return;
                    }
                    if (num == 0) {
                        layer.load();
                        $.ajax({
                            method: 'POST',
                            url: `${domain}/index.php/api/vcode.html`,
                            crossDomain: true,
                            xhrFields: {
                                withCredentials: true
                            },
                            data: {
                                tel
                            },
                        }).then(res =>{
                            layer.closeAll('loading');
                            layer.msg(res.info);
                            if (res.state == 1) {
                                vcode_number_handle();
                            }
                        });
                    }
                });
            }
        });
    });
}
